<template>
    <v-card :loading="loading">
        <v-card-title>
            <v-btn small icon @click="close" color="primary" class="mr-2">
                <v-icon>
                    mdi-close
                </v-icon>
            </v-btn>
            <h3>Machines</h3>
        </v-card-title>
        <v-divider class="ml-4 mr-5" />
        <v-card-text>
            <v-form v-model="valid" class="mt-2 mb-0">
                <v-row no-gutters>
                    <v-col cols="6">
                        <v-text-field
                            v-model="machine.name"
                            class="mr-2"
                            hide-details
                            prepend-icon="mdi-cogs"
                            label="Name *"
                            :rules="[rules.required]"
                            required
                        >
                        </v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-autocomplete
                            v-model="machine.process"
                            :items="processes"
                            item-text="name"
                            item-value="id"
                            class="ml-2"
                            prepend-icon="mdi-hammer-wrench"
                            label="Process: *"
                            :rules="[rules.required]"
                            required
                        >
                        </v-autocomplete>
                    </v-col>
                </v-row>
                <v-row cols="12">
                    <v-textarea
                        v-model="machine.description"
                        prepend-icon="mdi-text"
                        label="Description"
                        rows="1"
                        class="mx-3"
                        hide-details
                    >
                    </v-textarea>
                </v-row>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-spacer />
            <v-btn
                color="primary"
                class="mb-4"
                @click="saveMachine"
                rounded
                :loading="loading"
                :disabled="!valid"
            >
                SAVE
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapMutations } from 'vuex'
import API from '@/services/api'
import { objDiff } from '@/helpers/objDiff.js'

export default {
    name: 'MachinesForm',
    props: {
        machine: {
            type: Object,
            default: () => ({}),
        },
        originalMachine: {
            type: Object,
            default: () => ({}),
        },
        createForm: {
            type: Boolean,
            default: () => false,
        },
        settingId: {
            type: String,
            default: () => '',
        },
        processes: {
            type: Array,
            default: () => [],
        },
    },
    data: () => ({
        loading: false,
        valid: false,
        rules: {
            required: v => !!v || 'The value is required',
        },
    }),
    computed: {
        processDiff: function() {
            if (!this.createForm) {
                return objDiff(this.originalMachine, this.machine)
            } else {
                return null
            }
        },
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        async saveMachine() {
            if (this.createForm) {
                await this.create()
            } else {
                await this.update()
            }
        },
        async create() {
            try {
                this.loading = true
                const machine = await API.createMachine({
                    settingId: this.settingId,
                    ...this.machine,
                })
                this.$emit('addMachine', machine)
                this.$emit('close')
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async update() {
            try {
                this.loading = true
                const machine = await API.updateMachine({
                    settingId: this.settingId,
                    machineId: this.machine.id,
                    ...this.processDiff,
                })
                this.$emit('replaceMachine', machine)
                this.$emit('close')
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        close() {
            this.$emit('close')
        },
    },
}
</script>

<style></style>
